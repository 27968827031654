'use client';

import { CategoryId, CategoryStore, GeoEntry } from '@sbt-web/networking';
import {
  RecentSearchOption,
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@sbt-web/search-autocomplete';
import '@sbt-web/search-autocomplete/dist/index.css';
import React, { useContext, useEffect, useState } from 'react';
import { clickSearchBarTracking } from '../../../helpers/PulseTracking';
import { recentSearch, translateParamsToUrl } from '../navigateToListing';
import type { CategoryInfo } from '../types';
import CSSModule from './index.module.scss';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';

const KEYWORD_INPUT_NAME = 'main-keyword-field';

type SearchValue = {
  category?: CategoryInfo;
  query?: string;
  clothingType?: string;
  geo?: GeoEntry;
};

export const getSearchValue = (
  item?: SearchAutocompleteOption | null
): SearchValue => {
  if (!item) {
    return {};
  }

  const query = item.filters?.find((f) => f.uri === '/query/search_text');
  const autocompleteCategory = item.filters?.find((f) => f.uri === '/category');
  const clothingType = item.filters?.find((f) => f.uri === '/clothing_type')
    ?.values?.[0]?.key;

  let newQueryValue;
  if (query?.values?.[0]?.label) {
    newQueryValue = query.values[0].label;
  }

  let newCategory;
  if (autocompleteCategory?.values?.[0]?.key) {
    const catId = autocompleteCategory.values[0].key as CategoryId;
    const cat = CategoryStore.getCategoryById(catId);
    newCategory = { key: catId, label: cat.label };
  }

  return {
    query: newQueryValue,
    category: newCategory,
    clothingType,
    geo: item.geo,
  };
};

// Fires a search with the parameters of the recent search that has been selected.
export const applyRecentSearch = (recentSearchItem: RecentSearchOption) => {
  recentSearch(
    recentSearchItem.query,
    recentSearchItem.category,
    {
      ...recentSearchItem.geo,
      includeNearbyRegions: recentSearchItem.searchNearRegions,
    },
    recentSearchItem?.allFilters?.shp,
    {
      ...recentSearchItem?.allFilters,
      filters: {
        ...recentSearchItem?.allFilters?.filters,
        from: 'recentsearches',
      },
    }
  );
};

function navigateToListing(info: SearchValue) {
  const clothingType = info.clothingType;
  const allFilters = {
    filters: {
      ...(clothingType ? { clt: clothingType } : {}),
    },
    qso: false,
    shp: false,
    urg: false,
  } as const;
  translateParamsToUrl(
    info.query,
    info.category,
    info.geo && { geo: info.geo },
    false,
    allFilters
  );
}

const DEFAULT_THRESHOLD = 0.1;

interface Props {
  setCategoryInput: (c: CategoryInfo | undefined) => void;
  onUserSelect: (c: string | undefined) => void;
  setClothingType: (c: string | undefined) => void;
  setIsTiresiasAutoComplete?: (c: boolean) => void;
  setGeo?: (c: GeoEntry | undefined) => void;
}

export function Autocomplete({
  setCategoryInput,
  onUserSelect,
  setClothingType,
  setIsTiresiasAutoComplete,
  setGeo,
}: Readonly<Props>) {
  const onAutocompleteSelect = (
    item?: SearchAutocompleteOption | null
  ): void => {
    const info = getSearchValue(item);
    const isTiresiasAutoCompleteOption = !!item && item.probability !== 1;

    // Part of the A/B test for auto in Auto
    if (item?.goToCategoryAuto) {
      navigateToListing(info);
      return;
    }

    // In the B variant we don't wait for the submit to update the URL
    // but we rely on the user selection
    if (tiresiasAbTestVariant === 'B' && isTiresiasAutoCompleteOption) {
      optimizely?.track('subito_search_tiresias');
      navigateToListing(info);
      return;
    }

    if (info.category != undefined) {
      setCategoryInput(info.category);
    }

    onUserSelect(info.query);
    setClothingType(info.clothingType);
    setIsTiresiasAutoComplete?.(isTiresiasAutoCompleteOption);
    setGeo?.(info.geo);
  };

  const { optimizely } = useContext(OptimizelySubitoContext);

  const [tiresiasAbTestVariant, setTiresiasAbTestVariant] = useState<string>();

  const activateTiresiasAbTest = () => {
    if (!tiresiasAbTestVariant) {
      optimizely?.onReady().then(() => {
        const variant =
          optimizely?.activate('subito-search-typology-suggestion') ?? 'A';
        setTiresiasAbTestVariant(variant);
      });
    }
  };

  const handleApplyRecentSearch = (recentSearchItem: RecentSearchOption) => {
    optimizely?.track('subito_last_search');
    applyRecentSearch(recentSearchItem);
  };

  const [tiresiasThreshold, setTiresiasThreshold] = useState(DEFAULT_THRESHOLD);

  useEffect(() => {
    optimizely?.onReady().then(() => {
      const variant = optimizely?.activate(
        'subito-change-tiresias-search-threshold'
      );
      if (variant === 'B') {
        setTiresiasThreshold(0);
      }
    });
  }, [optimizely]);

  return (
    <div className={CSSModule['autocomplete-container']}>
      <SearchAutocomplete
        assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE}
        hadesBasePath={process.env.NEXT_PUBLIC_HADES_BASE}
        onSelect={onAutocompleteSelect}
        onDialogOpen={clickSearchBarTracking}
        name={KEYWORD_INPUT_NAME}
        labelId="main-keyword-label"
        onRecentSearchSelect={handleApplyRecentSearch}
        onFocus={activateTiresiasAbTest}
        useTiresiasNextVersion={tiresiasAbTestVariant === 'B'}
        tiresiasThreshold={tiresiasThreshold}
      />
    </div>
  );
}
